<script setup lang="ts">
const images = [
	{
		width: 90,
		height: 94
	},
	{
		width: 56,
		height: 54
	},
	{
		width: 58,
		height: 48
	},
	{
		width: 50,
		height: 50
	}
];
</script>

<template>
	<div class="coin-holder">
		<div v-for="(imageNumber, index) in images" :key="index" :class="`coin coin-${index + 1}`">
			<NuxtImg
				:width="images[index]?.width"
				:height="images[index]?.height"
				:src="`/nuxt-img/banners/coins/coin-${index + 1}.png`"
				alt="coin"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.coin-holder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

:deep(.coin) {
	position: absolute;
	animation: falling 4s linear infinite;
	width: max-content;
}

:deep(.coin-1) {
	top: 50%;
	right: 45%;
	animation: falling 3s linear infinite;

	@include media-breakpoint-down(sm) {
		top: 32%;
		left: 18%;
		right: auto;
	}

	img {
		animation: 6s wobble-animation infinite ease-in-out;

		@include media-breakpoint-down(sm) {
			width: 45px;
			height: 47px;
		}
	}
}

:deep(.coin-2) {
	top: 28%;
	right: 39%;
	animation: falling 5s linear infinite;

	@include media-breakpoint-down(sm) {
		top: 18%;
		left: 28%;
		right: auto;
	}

	img {
		animation: 3s wobble-animation infinite ease-in-out;

		@include media-breakpoint-down(sm) {
			width: 27px;
			height: 26px;
		}
	}
}

:deep(.coin-3) {
	top: 55%;
	right: 2%;
	animation: falling 2s linear infinite;

	@include media-breakpoint-down(sm) {
		top: 35%;
		right: 27%;
	}

	img {
		animation: 8s levitate-animation infinite ease-in-out;

		@include media-breakpoint-down(sm) {
			width: 29px;
			height: 24px;
		}
	}
}

:deep(.coin-4) {
	top: 2%;
	right: 35%;
	animation: falling 7s linear infinite;

	@include media-breakpoint-down(sm) {
		top: 2%;
		left: 32%;
		right: auto;
	}

	img {
		animation: 3s wobble-animation infinite ease-in-out;

		@include media-breakpoint-down(sm) {
			width: 25px;
			height: 25px;
		}
	}
}

@keyframes falling {
	0% {
		top: -10%;
		opacity: 1;
		rotate: -15deg;
	}

	50% {
		rotate: 15deg;
	}

	100% {
		top: 100%;
		opacity: 0;
		rotate: -15deg;
	}
}

@keyframes levitate-animation {
	0%,
	100% {
		transform: rotate(-10deg);
	}

	50% {
		transform: rotate(10deg) scale(1.1) translateY(20%);
	}
}

@keyframes wobble-animation {
	0%,
	100% {
		transform: rotate(9deg);
	}

	50% {
		transform: rotate(-6deg);
	}
}
</style>
